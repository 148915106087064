<template>
  <div class="loader">

  </div>
</template>
<script>
export default {
  name: 'Loader'
};
</script>
<style scoped>
.loader{
  @apply w-full block mb-2 h-10 rounded-full;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}
</style>
