<template>
  <div class="pagination" v-if="value && value.total && value.page">
    <span class="text-space-dark-blue">
      {{ $t('common.table.pagination.results')}} {{ (value.page >= 1 ? value.page === value.totalPages ? value.total : value.page * value.pageSize : 0) }} {{ $t('common.table.pagination.on') }} {{ value.total }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    value: {
      type: Object
    }
  }
};
</script>
<style scoped>
.pagination{
  @apply flex justify-end items-center bg-white rounded-b-xl p-2 w-full px-8;
}
</style>
