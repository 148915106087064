<template>
  <img
    v-if="!!user"
    :src="image"
    alt="avatar"
    class="w-10 h-10 rounded-full cursor-pointer"
    :class="className"
    :title="user.pseudo"
  >
</template>
<script>
export default {
  name: 'UserAvatar',
  components: {},
  props: {
    user: {
      //object or null
      type: Object
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      image: this.user && this.user?.avatar ? this.user.avatar : require(`@/assets/images/avatars/default.svg`)
    };
  },
  methods: {}
};
</script>
<style scoped>
</style>
