import {format} from "date-fns";
import {DATE_FORMAT_FR_SIMPLE} from "@/constants/dates/DateConstants";

/**
 * @class DateUtils - Utils for dates
 */
class DateUtils{
  /**
     * Format a date to a string with wished format
     * @param date {Date|String}
     * @param targetFormat {String}
     */
  formatDate(date, targetFormat= DATE_FORMAT_FR_SIMPLE){
    try {
      return format(date instanceof Date ? date : new Date(date), targetFormat);
    } catch (_) {
      return date;
    }
  }
}
export default new DateUtils();
