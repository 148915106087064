<template>
  <div class="list-loader flex flex-col">
    <Loader
      :key="i"
      v-for="i in lines"
    />
  </div>
</template>
<script>
import Loader from "@/components/loaders/Loader.vue";

export default {
  name: 'ListLoader',
  components: {Loader},
  props:{
    lines: {
      type: Number,
      default: 5
    },
  }
};
</script>
