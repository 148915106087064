<template>
  <div class="flex flex-col table-wrapper" :class="wrapperClassname">
    <div class="flex justify-between items-end">
      <slot name="title">
        <span v-if="title" class="text-2xl text-space-green font-raleway-extra-bold">{{ title }}</span>
      </slot>
      <div class="flex space-x-4">
        <slot name="beforeActions"/>
        <TableHeaderActions :actions="actions" v-if="actions && Object.values(actions).some(action => !!action)"/>
      </div>
    </div>
    <div
      class="overflow-y-auto table-container px-2"
      :class="tableContainerClassname"
    >
      <table
        class="table table-fixed w-full"
        :class="classes"
      >
        <slot name="colGroup"></slot>
        <thead v-if="headers && headers.length">
          <tr>
            <th
              v-if="!!onSelectAll && data.length"
              class="w-10"
            >
              <PrimaryCheckbox
                :value="data.length > 0 && selectedRows.length === data.length"
                class="mb-1"
                input-id="select-all"
                @input="onSelectAll"
              />
            </th>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :class="`${header.classname ? header.classname : ''}`"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <slot
          v-if="(!isLoading && data && data.length) || (!isLoading && data)"
          name="body"
        >
          <tbody></tbody>
        </slot>
      </table>
      <ListLoader
        v-if="isLoading"
        :lines="loaderSize"
      />
      <span
        v-if="!isLoading && (!data || !data.length) && !!paginationValues"
        class="-ml-2 w-full text-center text-space-dark-blue p-4 block">{{$t('common.no_result')}}
      </span>
    </div>
    <Pagination
      v-if="paginationValues"
      :value="paginationValues"
    />
  </div>
</template>
<script>
import PrimaryCheckbox from "@/components/checkbox/Checkbox.vue";
import ListLoader from "@/components/loaders/ListLoader.vue";
import TableHeaderActions from "@/components/Table/TableHeaderActions.vue";
import Pagination from "@/components/Table/Pagination.vue";

export default {
  name: 'Table',
  emits: ['scroll-end'],
  components: {Pagination, TableHeaderActions, ListLoader, PrimaryCheckbox},
  props: {
    title: {
      type: String
    },
    actions: {
      type: Object
    },
    headers: {
      type: Array[{
        name: String,
        value: String
      }]
    },
    classes: {
      type: String,
      default: ''
    },
    tableContainerClassname: {
      type: String,
      default: ''
    },
    wrapperClassname: {
      type: String,
      default: ''
    },
    onSelectAll: {
      type: Function
    },
    selectedRows: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    paginationValues: {
      type: Object
    },
    loaderSize: {
      type: Number,
      default: 5
    }
  },
  beforeDestroy() {
    const tableContainer = this.$el.querySelector('.table-container');
    tableContainer.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    //watch inner scroll and at the end of scroll emit event
    this.$nextTick(() => {
      const tableContainer = this.$el.querySelector('.table-container');
      tableContainer.addEventListener('scroll', this.handleScroll);
    });
  },
  methods: {
    handleScroll() {
      const tableContainer = this.$el.querySelector('.table-container');
      if (tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight) {
        this.$emit('scroll-end');
      }
    }
  },
  watch: {
    //paginationValues
    paginationValues: {
      handler() {
        this.$nextTick(() => {
          //when the page is reset to 1 for whatever reason, scroll to top
          if (this.paginationValues && this.paginationValues.page === 1) {
            const tableContainer = this.$el.querySelector('.table-container');
            tableContainer.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
        });
      },
      deep: true
    }
  }
};
</script>
<style scoped>

table {
  border-collapse: separate;
  border-spacing: 0 2px;
}

.table-container {
  @apply mx-2 rounded-xl;
}

.table-wrapper:has(div):has(.pagination) .table-container{
  @apply rounded-t-xl rounded-b-none;
}

table >>> thead th {
  @apply p-2;
}

table >>> td {
  @apply text-space-dark-blue p-2 align-middle border-none;
}

table >>> th:first-child {
  @apply pl-2;
}

table >>> th {
  @apply text-space-dark-blue font-raleway-extra-bold text-xl text-left sticky top-0 bg-white border-none z-10;
}

table >>> tbody {
  @apply w-full pt-5;
}

table >>> tr:hover {
  @apply bg-gray-100;
}

>>> .selected {
  @apply bg-space-dark-blue text-white;
}

>>> .selected:hover {
  @apply bg-space-dark-blue opacity-80;
}

>>> td:first-child {
  @apply rounded-l-full pl-4;
}

>>> td:last-child {
  @apply rounded-r-full;
}

>>> .selected td {
  @apply text-white;
}

>>> .list-loader {
  @apply pr-12 mt-2;
}
</style>
