<template>
  <div
    v-if="Object.values(actions).some(action => !!action.method)"
    class="flex space-x-3 items-center justify-center items-center">
    <IconButtonAdd
      v-if="actions.add && actions.add.method"
      :tooltip-text="actions.add.label"
      @click="actions.add.method()"
    />
    <IconButtonDelete
      v-if="actions.remove && actions.remove.method"
      :tooltip-text="actions.remove.label"
      @click="actions.remove.method()"
    />
  </div>
</template>
<script>
import IconButtonAdd from "@/components/buttons/IconButtonAdd.vue";
import IconButtonDelete from "@/components/buttons/IconButtonDelete.vue";

export default {
  name: 'TableHeaderActions',
  components: {IconButtonDelete, IconButtonAdd},
  props: {
    actions: {
      type: Object,
      default: () => ({
        add: undefined,
        remove: undefined
      })
    }
  }
};
</script>
